<template>
  <div class="content">
    <div class="content__party">
      <h1 class="title title--big title--color">FAQ</h1>
    </div>
    <div class="faq-list">
      <div
        @click.capture="toggleFaq($event)"
        v-for="faq in faqs"
        :key="faq.id"
        class="faq-list__item"
      >
        <div class="faq-list__decor">
          <img src="@/assets/img/faq-plus.svg" alt="plus-icon">
        </div>
        <h3 class="title title--small title--upper">{{ faq.name }}</h3>
        <div class="faq-list__content text-content" v-html="faq.description"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FAQList',
  data() {
    return {
      faqs: [],
      openFaq: false
    };
  },
  mounted() {
    this.fetchFaqs();
  },
  methods: {
    fetchFaqs() {
      this.$store.dispatch('partner/GET_FAQS')
        .then(response => {
          this.faqs = response.data;
        })
        .catch(error => {
          this.$notify({
            type: 'warn',
            title: 'Внимание!',
            text: `Ошибка: ${ error.message }`
          });
        });
    },
    toggleFaq(event) {
      event.currentTarget.classList.toggle('faq-list__item--open');
    }
  },
};
</script>

<style lang="sass">
@import "@/assets/common/index.scss"

.faq-list__item
  position: relative
  margin-bottom: 25px
  padding: 20px 35px 20px 20px
  border-radius: 10px
  box-shadow: 0 0 4px rgba(151, 151, 151, 0.25)
  background-color: #F8FDFF
  word-break: break-all
  cursor: pointer

  &:last-child
    margin-bottom: 0

.faq-list__item--open
  .faq-list__decor
    transform: rotate(45deg)

  .faq-list__content
    padding: 15px 5px 10px 0
    max-height: 500px

.faq-list__content
  overflow-y: auto
  overflow-x: hidden
  max-height: 0
  padding: 0 5px 0 0
  transition: max-height 0.3s, padding 0.4s
  scrollbar-color: $color-theme #f2f3f8
  scrollbar-width: thin

  &::-webkit-scrollbar
    width: 2px

  &::-webkit-scrollbar-track
    border-radius: 5px
    background-color: #f2f3f8

  &::-webkit-scrollbar-thumb
    border-radius: 5px
    background-color: $color-theme

.faq-list__decor
  position: absolute
  right: 20px
  top: 25px
  width: 15px
  height: 15px
  transition: transform 0.3s
</style>